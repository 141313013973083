#dropdown-basic{
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    color:#8492a6;
    font-family:'nunito';
    font-size:18px;
    font-weight:700;
    outline:1px solid #1a81c5;
    border:none;
    margin-bottom:52px
}
#dropdown-basic:hover{
 background-color:#f8f8f8
}
#dropdown-basic:focus{
    background-color:#f8f8f8
   }
.dropdown-toggle::after {
    
    font-family: 'nunito'; /* Ensure the correct font family */
   
    color: #8492a6; /* Change the color of the icon */
    font-size: 28px; 
  }
  .dropdown-menu{
    width:100%;
    color:#8492a6;
    font-family:'nunito';
    font-size:15px;
    font-weight:600;
  }
  .pp{
    background-color:#eff2f7;
    color:#273444;
    border-radius:5px;
    font-family:'nunito';
    font-size:16px;
    font-weight:600;
    margin-bottom:52px;

    padding:12px 28px 12px 28px
    
  }
  .rowbu{
    display:flex;
    flex-wrap: wrap;
    justify-content:space-between
  }
  .rowbut{
    display:flex;
    flex-wrap: wrap;
    justify-content:space-between
  }
  .pl{
    background-color:#eff2f7;
    color:#273444;
    border-radius:5px;
    font-family:'nunito';
    font-size:16px;
    font-weight:600;
    margin-bottom:52px

    
  }
  .paycoin{
    display:flex;
    align-items:center;
    justify-content:center;
    padding:12px 48px 12px 48px
  }
  .amountin{
    margin-bottom:52px;

  }
  
  .amountins input{
    border:1px solid #e0e6ed;
    width:100%;
    padding:12px;
    font-family:'nunito'
  }
  .amountins input:focus{
    border:1px solid #1a81c5;
    outline:none;
    border-radius:10px    ;
    -webkit-border-radius:10px    ;
    -moz-border-radius:10px    ;
    -ms-border-radius:10px    ;
    -o-border-radius:10px    ;
}
.amountins input[type=number]::-webkit-inner-spin-button {
    opacity: 0;
    
  }
  .dropd{
    padding-inline:20px

  }
  .basic{
    padding-inline:20px
  }
  .basic h2{
    font-family:'nunito';
    color:#8492a6;
    font-size:16px;
    line-height:27px;
    margin-bottom:10px
  }
  .plans-flex{
    display:flex;
    flex-wrap: wrap;
    justify-content:space-between;
    margin-bottom:32px
  }
  .activef{
    background-color:#1a81c5;
    color:#fff
  }
  .activeg{
    background-color:#1a81c5;
    color:#fff

  }
  .selected{
    width:70%
  }
  hr{
    padding-block:14px
  }
  .selected-side{
    width: 270px;

    
    padding-block: 18px;
    padding-inline:14px ;
    border:1px solid #d9e0eb;
    background-color: #ffffff;
    border-radius: 10px;
    
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
    -webkit-transition: box-shadow 0.3s ease-in-out;
    -moz-transition: box-shadow 0.3s ease-in-out;
    -ms-transition: box-shadow 0.3s ease-in-out;
    -o-transition: box-shadow 0.3s ease-in-out;
}
  

@media screen and (max-width: 600px) {
  .selected-side{
    width: 100%;
  }
  .selected{
    width:100%
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .selected{
    width:90%
  }
  .selected-side{
    width: 90%;
    margin-inline:auto
  }
}