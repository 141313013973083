@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Nunito";
  src: local("Nunito"),
    url("./assets/fonts/Nunito-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "NunitoSemi";
  src: local("NunitoSemi"),
    url("./assets/fonts/Nunito-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "NunitoMedium";
  src: local("NunitoMedium"),
    url("./assets/fonts/Nunito-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "NunitoBold";
  src: local("NunitoBold"),
    url("./assets/fonts/Nunito-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "NunitoExtra";
  src: local("NunitoExtra"),
    url("./assets/fonts/Nunito-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "DMserif";
  src: local("DMserif"), url("./assets/fonts/DMserif.ttf") format("truetype");
}

/* Add this to your styles or Tailwind CSS configuration */
.overlay::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to right,
    rgba(6, 49, 58, 0.9),
    rgba(6, 49, 58, 0.4)
  ); 
}
.nunito {
  font-family: "Nunito";
}
.nunitoMedium {
  font-family: "NunitoMedium";
}
.nunitoSemi {
  font-family: "NunitoSemi";
}
.nunitoBold {
  font-family: "NunitoBold";
}
.nunitoExtra {
  font-family: "NunitoExtra";
}
.dmSerif {
  font-family: "DMserif";
}


.signbut {
  background-color: #1a81c5;
  box-shadow: 0 3px 5px 0 rgba(90, 39, 179, 0.3);
  border-radius: 5px 5px 5px 0px;
  font-weight: 500;
  letter-spacing: 1px;
  border: 1px solid #1a81c5;
  -webkit-border-radius: 5px 5px 5px 0px;
  -moz-border-radius: 5px 5px 5px 0px;
  -ms-border-radius: 5px 5px 5px 0px;
  -o-border-radius: 5px 5px 5px 0px;
}
.signbut:hover {
  background-color: #423c8c;
  border: 1px solid #1a81c5;
}
p.linking:hover {
  color: #0056b3;
}

.carouselContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 40%;
  margin-left: 200px;
}
.carouselHeader {
  font-size: 51px;
  font-weight: 300;
  margin-bottom: 8px;
  padding-bottom: 19px;
  line-height: 1.2;
  color: #fff;
}
.carouselBody {
  margin-bottom: 16px;
  padding-bottom: 35px;
  font-size: 21px;
  text-align: left;
  color: #fff;
}
.carouselButton {
  background-color: #1a81c5;
  box-shadow: 0 3px 5px 0 rgba(90, 39, 179, 0.3);
  border-radius: 5px 5px 5px 0px;
  font-weight: 500;
  letter-spacing: 1px;
  border: 1px solid #1a81c5;
  -webkit-border-radius: 5px 5px 5px 0px;
  -moz-border-radius: 5px 5px 5px 0px;
  -ms-border-radius: 5px 5px 5px 0px;
  -o-border-radius: 5px 5px 5px 0px;
}
.carouselButton:hover {
  background-color: #423c8c;
  border: 1px solid #1a81c5;
}
.accountButton{
  background-color: #f8f9fc;
  box-shadow: 0 3px 5px 0 rgba(90, 39, 179, 0.3);
  border-radius: 5px 5px 5px 0px;
  font-weight: 500;
  color: #3c4858;

  letter-spacing: 1px;
  border: 1px solid #dee2e6;
  -webkit-border-radius: 5px 5px 5px 0px;
  -moz-border-radius: 5px 5px 5px 0px;
  -ms-border-radius: 5px 5px 5px 0px;
  -o-border-radius: 5px 5px 5px 0px;
}
.accountButton:hover {
  background-color: #423c8c;
  border: 1px solid #1a81c5;
  color: #f8f9fc;
}
.mobileSign {
  display: none;
}
/* Navbar.css */
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: background-color 0.3s ease;
  position: fixed;
  width: 100%;
  z-index: 1000;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  -ms-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
}

.navbar.scrolled {
  box-shadow: 0 0 25px 0 rgba(90, 39, 179, 0.3);
  background-color: #fff; 
}


.navbar-container {
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}


.nav-links-container {
  display: flex;
  align-items: center;
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}
.linking {
  margin-right: 30px;
  font-size: 17px;
  font-weight: 600;
}

.scrolled .linking {
  color: #2c2c2c;
  font-weight: 600;
}

.mobile-menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 30px;
  height: 3px;
  background-color: #1a81c5;
  margin: 4px 0;
  transition: 0.4s;
}

.scrolled .bar{
  background-color: #1a81c5;
}
.open .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-10px, 6px);
}

.open .bar:nth-child(2) {
  opacity: 0;
}

.open .bar:nth-child(3) {
  transform: rotate(45deg) translate(-10px, -6px);
}






.second-section{
  width:100%;
  overflow: hidden;
}
.second-section-text{
  width:40%;
  
}
.section-text-crumb{
  color:#1a81c5;
  font-size:16px;
  margin-bottom:12px;

}
.section-text-header{
  color:#000;
  font-size:42px;
  font-weight:700;
  line-height:60px;
  margin-bottom:12px;

}
.section-text-content{
  color:#3c4858;
  font-size:19px;
  font-weight:600;
  line-height:30px;
  margin-bottom:32px;
}
.second-section-image{
  width:40%;
  
}
.section-wrapper{
  padding-block:90px
}
.third-section-wrapper{
  width:80%;
  display:flex;
  justify-content:space-between
}
.third-section-text{
  width:35%
}
.third-section-text-crumb{
  font-size:15px;
  font-weight:700;
  color:#161C2D;
  width:95%;
  margin-bottom:12px

}
.third-section-text-content{
  font-size:24px;
  font-weight:500;
  color:#161C2D;
  width:95%;
  margin-bottom:32px

}
.third-section-image{
  width:65%
}




.fourth-section-wrapper{
  width:80%;
  display:flex;
  flex-wrap: wrap;
  justify-content:space-between
}
.fourth-section-div{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:47%
}
.fourth-section-image{
  background-color:#ece9ff;
  border:1px solid #ccdcfc;
  border-radius: 6px 6px 6px 0;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}
.fourth-section-header{
  font-size:20px;
  font-weight:600;
  line-height:30px;
  color:#3c4858;
  padding-block:24px;
  text-align:center
}
.fourth-section-text-content{
  font-size:16px;
  color:#8492ac;
  line-height:25px;
  text-align:center
}
.fourth-section-subject{
  font-size:36px;
  font-weight:700;
  margin-bottom:100px;
  color:#161c2d

}


.fifth-section-wrapper{
  width:80%
}
.fifth-section-div{
  width:100%;
 
}

.fifth-section-subject{
  font-size:36px;
  font-weight:700;
  color:#161c2d
}
.mission-text{
  font-size:18px;
  font-weight:500;
  padding-block:20px;
  line-height:29px;
  color:#8492ac;
  width:80%;
  text-align:center;
}
.fifth-section-header{
  font-size:18px;
  font-weight:600;
  line-height:27px;
  color:#161c2d;
  margin-bottom:10px
}
.fifth-section-text-content{
  font-size:16px;
  font-weight:500;
  line-height:25.6px;
  color:#8492ac

}
.card-double{
  display:flex;
  justify-content:center;
  align-items:start;
  width:70%;
  margin-top:50px
  
}
.fifth-section-sc{
  display:flex;
  align-items:start;
  justify-content:center;
  width:100%;
  margin-bottom:80px;
  
}
.fifth-section-content{
  text-align:left;
  display:flex;
  flex-direction:column;
  align-items:start;
  width:100%;
  margin-left:15px
}
.fifth-section-image{
  width:30%
}
.icon-box{
  border-radius: 50px 50px 50px 0px;
  padding:15px;
  background-color:#ece9ff;
  display:flex;
  align-items:center;
  justify-content:center;
}



.sixth-section-wrapper{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  width:80%;
  align-items:start

}
.sixth-section-div{
  width:31%;
  display:flex;
  flex-direction:column;
  justify-content:start;
  align-items:start;
  margin-bottom:15px

}
.sixth-section-subject{
  font-size:30px;
  font-weight:700;
  margin-bottom:100px;
  color:#161c2d

}

.sixth-section-header{
  font-size:24px;
  font-weight:600;
  color:#161c2d;
  line-height:20px;
  margin-block:15px
}
.sixth-section-text-content{
  font-size:16px;
  color:#161c2d;
  line-height:25.6px;
}
.seventh-wrapper{
  padding-block:90px;
  width:80%;
  display:flex;
  justify-content:center;
  align-items:center;
}
.seventh-section{
  width:100%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
}

.seventh-section-image{
  width:40%
}

.seventh-section-text{
  width:40%;
  text-align:left;
margin-left:80px
}
.seventh-section-header{
  font-size:36px;
  color:#1a81c5;
  font-weight:700;
  line-height:54px;
  margin-bottom:20px
}
.seventh-text{
  font-size:16px;
  color:#8492a6;
  line-height:25.6px
}
.seventh-link{
  font-size:16px;
  color:#1a81c5;
  font-weight:600;
  text-decoration:underline;
  margin-top:10px

}
.eight-section-wrapper{
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-block: 90px;
}
.eight-outer-div{
  display: flex;
  justify-content: space-between;
  width:80%
}
.eight-section-div{
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 10px;
  padding: 2rem;
  width: 30%;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  -webkit-transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  -ms-transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.eight-section-div:hover{
   
    transform: translateY(-5px); /* Move the card upwards */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow size */
  }

.eight-card-header{
  text-align: center;

  font-size: 1.1rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #1b1e28;
    
}
.eight-subject{
  color: #1a81c5;
  font-size: 24px;
  font-weight: 600;
  line-height: 35px;
  padding-top: 60px;
  margin-bottom: 25px;

}
.eight-tagline{
  color: #8492a6;
  line-height: 1.6;
  font-size: 16px;
  margin-bottom: 25px;
}
.eight-header-wrapper{
  border:1px solid #e9ecef;
  padding: 4px 16px 4px 16px;
  border-radius: 50rem ;
  -webkit-border-radius: 50rem ;
  -moz-border-radius: 50rem ;
  -ms-border-radius: 50rem ;
  -o-border-radius: 50rem ;
  margin-bottom: 10px;
}
.eight-price{
  text-align: center;

  display: block;
    font-size: 36px;
    font-weight: 700;
    color: #1a81c5;
    margin-bottom: 0.8rem;
}
.divider{
  margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1)
}
.eight-content{
  padding: 0.5rem 0;
    font-size: 1.1rem;
    color: #1b1e28;
    display: flex;
    align-items: center;
    
}
.cardbut{
  display: flex;
  justify-content: center;
}
.eight-month{
  text-align: center;
  font-size: 0.9rem;
  color: #8492a6
}


.ninth-section-div{
    width:30%;
    background-color: #01395e;
    padding: 2rem;
    margin-top: 3rem;
    border-radius:8px ;
    -webkit-border-radius:8px ;
    -moz-border-radius:8px ;
    -ms-border-radius:8px ;
    -o-border-radius:8px ;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    -webkit-transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    -moz-transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    -ms-transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    -o-transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.ninth-section-div:hover{
   
  transform: translateY(-5px); /* Move the card upwards */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow size */
}
.tenth-section-wrapper{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block: 90px;
}
.ratings{
  display:flex;
  flex-direction: row;

}
.testimonial-card{
  background: #fff;
  padding: 30px ;
  border-radius: 15px;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.15);
  text-align: center;
  width: 33%;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.title{
  margin-bottom: 10px;
  margin-top:10px;
  font-size: 20px;
  line-height: 1.5em;
  font-weight: 600;
  color: #161C2D;
}
.role{
  font-size: 14px;
  color:#999999;
  line-height: 21px;
  margin-bottom: 10px;
}
.content{
  font-size:16px;
  color:#444444;
  margin-top: 22px;
}
.card{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.eleven-section{
  display: flex;
  align-items: center;
flex-direction: column;
padding-block: 30px;
}
.eleven-section-wrapper{
  width:80%;
  padding: 48px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: #1a81c5;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.eleven-header{
  font-size:36px;
  margin-bottom:20px;
  font-weight: 700;
  color:white

}
.eleven-content{
  font-size: 16px;
  line-height: 25.6px;
  color:white
}
.footer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0c4c76;
}
.footertext{
  color:#ffffff;
  width:80%;
  margin: auto;
  padding-block: 30px;
}
.footer-row{
  width:30%
}
.footer-header{
  font-size: 20px;
  font-weight:500;
  color:#f8f9fc;
  margin-bottom: 25px;
}
.footer-para{
  font-size: 16px;
  line-height: 25.6px;
  color: #fff;
  margin-bottom: 10px;
}
.footer-content{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 60px 0 60px 0;
  width:80%
}
.social-icons{
  display: flex;
  flex-direction: row;
}
.social-box{
  border: 1px solid #adb5db;
  padding: 8px;
  margin-left:4px;
  border-radius:  6px 6px  6px 0;
  -webkit-border-radius:  6px 6px  6px 0;
  -moz-border-radius:  6px 6px  6px 0;
  -ms-border-radius:  6px 6px  6px 0;
  -o-border-radius:  6px 6px  6px 0;
}
.social-box:hover{
background-color: #1a81c5;
}
.footerdiv{
  border-top: 1px solid #A3B9A9;
  width: 100%;
}
.eleven-section-text{
  width: 70%;
}





























@media screen and (min-width: 601px) {
  .carouselContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 60%;
    margin-left: 100px;
  }
  .carouselHeader {
    font-size: 42px;
    font-weight: 300;
    margin-bottom: 8px;
    padding-bottom: 15px;
    line-height: 1.2;
    color: #fff;
  }
  .carouselBody {
    margin-bottom: 13px;
    padding-bottom: 30px;
    font-size: 18px;
    text-align: left;
    color: #fff;
  }
}
@media screen and (min-width: 950px) {
  .carouselContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 40%;
    margin-left: 200px;
  }
  .carouselHeader {
    font-size: 42px;
    font-weight: 300;
    margin-bottom: 8px;
    padding-bottom: 15px;
    line-height: 1.2;
    color: #fff;
  }
  .carouselBody {
    margin-bottom: 16px;
    padding-bottom: 39px;
    font-size: 21px;
    text-align: left;
    color: #fff;
  }
}

@media screen and (max-width: 768px) {
  .nav-links {
    opacity: 0;
    visibility: hidden;
    flex-direction: column;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 60px; /* Adjust this based on your design */
    left: 0;
    background-color: #fff; /* Change this to your desired background color for mobile */
    z-index: 999;
    transform: translateY(-100%);
    transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out,
      transform 0.4s ease-in-out;
  box-shadow: 0 0 25px 0 rgba(90, 39, 179, 0.3);
      
  }
  .linking {
    color:#3c4858;
    font-weight:500;
    padding-block:10px
  }


  .nav-links.show-mobile {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .mobile-menu-icon {
    display: flex;
  }
  .mobileSign {
    
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;

  }
  .deskSign {
    display: none;
  }
  .section-wrapper{
    display:flex;
    flex-direction:column;
   }
   .second-section-text{
    width:80%

   }
   .second-section-image{
    width:80%

   }
   .third-section-wrapper{
    display:flex;
    flex-direction:column;
   }
   .third-section-text{
    width:100%

   }
   .third-section-image{
    width:100%;
    margin-top:15px;

   }
   .fourth-section-wrapper{
    width:100%;
    
    flex-direction:column;
    justify-content:space-between
  }
  .fourth-section-text-content{
    width:100%;

  }
  .fourth-section-subject{
    font-size:28px;
    margin-bottom:70px
  }
  .fourth-section-div{
    width:80%;
    margin-bottom:20px
  }
  .card-double{
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:start;
    width:100%;
    margin-top:50px
    
  }
  .fifth-section-wrapper{
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    width:80%;
    
  }
  .fifth-section-subject{
    margin-top:-70px;
    text-align: center;

  }
  .fifth-section-image{
    width:80%
  }
  .sixth-section-wrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:80%;
    align-items:start
  
  }
  .sixth-section-div{
    width:100%;
    margin-bottom:35px
  }
  .seventh-wrapper{
    width:80%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
  }
  .seventh-section-image{
    width:90%
  }
  .seventh-section-text{
    width:100%;
    display:flex;
    flex-direction:column;
    margin: auto;
    justify-content:start;
    align-items:start;

  }
  
  .seventh-section-header{
    font-size:26px;
    margin-top:15px;
    line-height: 25px;
  }
  .eight-outer-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:80%
  }
  .eight-section-div{
    margin-bottom: 20px;
    
    width: 100%;
    
  }
  .ninth-section-div{
    margin-bottom: 20px;
    
    width: 100%;
    
  }
  .tenth-section{
 
    display:flex;
    
    justify-content: center;
    align-items: center;
  }
  .tenth-section-wrapper{
    width: 80%;
    display:flex;
    
    justify-content: center;
    align-items: center;
  }
  .testimonial-card{
    width: 80%;
  }
  .eleven-section-wrapper{
    width:80%;
    padding: 48px;
    display: flex;
   flex-direction: column;
    justify-content: space-between;
    
  }
  .eleven-section-text{
    width:100%
  }
  .footer-content{
    display: flex;
  flex-direction: column;
  justify-content: space-between;
  width:80%
  }
  .footer-row{
    width:100%;
    padding-top:30px
  }
}

@media only screen and (max-width: 600px) {
  .navbar-container {
  flex-direction: row;
  width: 95%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
  .carouselContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: auto;
  }
  .carouselHeader {
    font-size: 32px;
    font-weight: 300;
    margin-bottom: 8px;
    padding-bottom: 10px;
    line-height: 1.2;
    color: #fff;
    text-align: center;
  }
  .carouselBody {
    margin-bottom: 8px;
    padding-bottom: 20px;
    font-size: 16px;
    text-align: center;
    color: #fff;
  }
  .second-section-text{
    width:90%

   }
   .second-section-image{
    width:90%

   }
   .third-section-wrapper{
    width:90%;

   }
   
   .third-section-image{
    width:100%;
    margin-top:15px;

   }
   .fourth-section-wrapper{
    width:100%;
    
    flex-direction:column;
    justify-content:space-between
  }
  .fourth-section-text-content{
    width:100%;

  }
  
  .fourth-section-div{
    width:90%
  }
  .card-double{
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:start;
    width:100%;
    margin-top:50px
    
  }
  .fifth-section-wrapper{
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    width:90% !important;
    
  }
  .sixth-section-wrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:90%;
    align-items:start
  
  }
  .seventh-wrapper{
    width:80%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
  }
  .seventh-section-image{
    width:100%
  }
  .seventh-section-text{
    width:100%;
    display:flex;
    flex-direction:column;
    margin: auto;
    justify-content:start;
    align-items:start;

  }
  
  .seventh-section-header{
    font-size:24px;
    margin-top:15px;
    line-height: 25px;
  }
  .eight-outer-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:90%
  }
  .eight-section-div{
    margin-bottom: 20px;
    
    width: 100%;
    
  }
  .ninth-section-div{
    margin-bottom: 20px;
    
    width: 100%;
    
  }
  .tenth-section-wrapper{
    width: 100%;
    display:flex;
    justify-content: center;
  }
  .testimonial-card{
    width: 100%;
  }
  .footer-content{
    display: flex;
  flex-direction: column;
  justify-content: space-between;
  width:90%
  }
  .footer-row{
    width:100%;
    padding-top:30px
  }
  .eleven-section-wrapper{
    width:90%;
    padding: 48px;
    display: flex;
   flex-direction: column;
    justify-content: space-between;
    
  }
  .eleven-section-text{
    width:100%
  }
}

@media screen and (min-width: 769px) and (max-width: 844px) {
  .navbar-container {
    width: 85%;
  }
  .deskSign {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .signbut {
    width: 90px;
  }
  
  .section-text-header{
    font-size:32px
  }
  .section-text-content{
    font-size:15px
  }
  .third-section-text-crumb{
    font-size:14px;
    font-weight:700
  }
  .third-section-text-content{
    font-size:15px
  }
}