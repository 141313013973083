.managed-contain{
    margin-bottom: 12px;
    margin-inline: auto;
    padding-block: 24px;
    padding-inline:14px ;
    border:1px solid #d9e0eb;
    background-color: #ffffff;
    border-radius: 10px;
    width: 100%;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
    -webkit-transition: box-shadow 0.3s ease-in-out;
    -moz-transition: box-shadow 0.3s ease-in-out;
    -ms-transition: box-shadow 0.3s ease-in-out;
    -o-transition: box-shadow 0.3s ease-in-out;
}
.managed-header h1{
    font-size:32px;
    font-weight:500;
    color:#3c4858; 
    margin-bottom:25px;
}
.managed-header p{
color:#8492a6;
font-size:16px;

}
.managed-header button{
    padding:12px;
    color:#fff;
    font-family:'nunito';
    background-color:#484d4b;
    border-radius:10px 10px 10px 0;
    -webkit-border-radius:10px 10px 10px 0;
    -moz-border-radius:10px 10px 10px 0;
    -ms-border-radius:10px 10px 10px 0;
    -o-border-radius:10px 10px 10px 0;
    margin-top:25px
}

.managed-header button:hover{
    background-color:#1a81c5;

}
.table-head th{
    font-size:12px;
    font-family:'nunito';
    font-weight:600;
    color:#8492a6;
    line-height:27px;

}
.table-head td{
    font-size:13px;
    font-family:'nunito';
    font-weight:600;
    color:#8492a6;
    line-height:27px;

}
.header2{ 
    font-size:20px;
    font-family:'nunito';
    font-weight:600;
    color:#3c4858;
    margin-top:52px;  
}