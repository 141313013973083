#google_translate_element select{
   background-color:#29302D;
   color:#ffffff;
   border: 1px solid rgb(182, 179, 179);
   border-radius:3px;
   padding:6px 8px;
   font-family:'nunito'
 }
  .goog-logo-link{
    display:none !important;
   }
 .goog-te-gadget{
  color:transparent!important;
  }
  .goog-te-banner-frame{
    display:none !important;
    }
    #goog-gt-tt, .goog-te-balloon-frame
{
display: none !important;
}
.goog-text-highlight 
{ 
background: none !important;
box-shadow: none !important;
}